/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, before, css } from 'glamor'
import { rem } from 'polished'
import { breakpoints, colors } from './../../theme'

const { MEDIUM_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { NEUTRAL_COLOR_10, NEUTRAL_COLOR_40, SECONDARY_COLOR_50 } = colors

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: rem(24),
      marginRight: 'auto',
      marginBottom: rem(72),
      marginLeft: 'auto',
      maxWidth: rem(700),
      '& ul': {
        paddingLeft: 30,
        listStyle: 'initial',
      },
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(112),
    }),
  ),
  image: merge(
    {
      marginBottom: rem(-12),
      width: '100%',
      maxHeight: rem(550),
      objectFit: 'cover',
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(12),
    }),
    media(HUGE_BREAKPOINT, {
      marginBottom: rem(24),
    }),
  ),
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  author: {
    marginTop: rem(24),
    marginBottom: rem(24),
    paddingTop: rem(24),
    paddingBottom: rem(24),
    borderTopWidth: 1,
    borderTopColor: NEUTRAL_COLOR_10,
    borderTopStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: NEUTRAL_COLOR_10,
    borderBottomStyle: 'solid',
  },
  meta: {
    display: 'inline',
    color: NEUTRAL_COLOR_40,
  },
  metaAuthor: {
    marginLeft: rem(4),
  },
  startLink: {
    marginTop: rem(12),
  },
  endLink: merge(
    {
      marginTop: rem(12),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(36),
    }),
  ),
  content: merge(
    {
      ' img': {
        margin: `${rem(32)} auto 0`,
        maxWidth: '100%',
      },
      ' .block-img': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    css.global('div[data-oembed]', {
      position: 'relative',
      height: '0',
      paddingBottom: '56.25%',
    }),
    css.global('div[data-oembed] iframe', {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    }),
  ),
  relativePostContainer: merge(
    {
      width: '100%',
      paddingTop: rem(60),
      position: 'relative',
    },
    before({
      content: ' ',
      display: 'block',
      width: '100vw',
      height: rem(2),
      backgroundColor: NEUTRAL_COLOR_10,
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    }),
  ),
  relativePostTitle: {
    textAlign: 'center',
  },
  relativePost: merge(
    {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: rem(50),
    },
    media(MEDIUM_BREAKPOINT, {
      flexDirection: 'row',
    }),
  ),
  categorie: {
    color: SECONDARY_COLOR_50,
  },
  cta: {
    margin: `${rem(20)} auto`,
  },
})

export default styles
