/* @flow */
/* eslint indent: 0 */

import * as React from 'react'
import { css } from 'glamor'
import moment from 'moment'
import slugify from 'slugify'
import { Disqus } from 'gatsby-plugin-disqus'
import 'moment/locale/fr'
import type { Location } from 'react-router'
import {
  Wrapper,
  Author,
  Image,
  Title,
  Span,
  Share,
  Button,
  PostBodyBanner,
  PostBodyClickableImage,
  Post,
} from './../../components'
import { DefaultLayout as Layout } from './../../layouts'
import { t } from './../../translations'

import styles from './styles'
import blogStyles from '../Blog/styles'

type Article = {
  uid: string,
  first_publication_date: Date,
  relativeArticles: [Article],
  data: {
    illustration: {
      url: string,
    },
    title_post: {
      text: string,
    },
    meta_description: {
      text: string,
    },
    thumbnail1: {
      Wino_thumbnail: {
        url: string,
      },
    },
    blog_post: {
      html: string,
    },
    socialnetworkthumbnail: {
      thumbnails: {
        Facebook: {
          url: string,
        },
      },
    },
    body: [any],
    categories: string,
  },
}

type Props = {
  location: Location,
  url: string,
  contentType: string,
  pageContext: {
    baseUrl: string,
    author: {
      data: {
        image: {
          url: string,
        },
        name: {
          text: string,
        },
        description: {
          text: string,
        },
      },
    },
    post: Article,
  },
}

moment.locale('fr')

class ArticleTemplate extends React.Component<Props> {
  static defaultProps = {
    contentType: 'article',
  }

  reduceSummary(summary: ?string) {
    if (!summary) return ''
    const words = summary.replace(/<(?:.|\n)*?>/gm, '')
    if (words.split(' ').length > 30) {
      return `${words
        .split(' ')
        .slice(0, 30)
        .join(' ')} ...`
    }
    return words
  }

  formatBody(body: string): string {
    return body.replace(/<p><\/p>/g, '<br />')
  }
  render() {
    const { contentType, pageContext, location } = this.props
    const { post, baseUrl } = pageContext
    const author = post.data.author.document
    let url = location.href

    const disqusConfig = {
      url,
      identifier: post.uid,
      title: post.data.title_post.text,
    }

    if (!url) {
      url = `${baseUrl.slice(0, -1)}${location.pathname}`
    }

    return (
      <Layout
        url={url}
        contentType={contentType}
        name={post.data.title_post.text}
        title={post.data.title_post.text}
        description={post.data.meta_description.text}
        contentImage={post.data.socialnetworkthumbnail.thumbnails.Facebook.url}
      >
        <Image
          className={css(styles.image)}
          src={post.data.illustration.url}
          srcset={
            post.data.illustration.Miniature
              ? `
                  ${post.data.illustration.url} 1490w,
                  ${post.data.illustration.Tablet.url} 1024w,
                  ${post.data.illustration.Mobile.url} 500w
                `
              : undefined
          }
        />
        <Wrapper>
          <div className={css(styles.container)}>
            <div className={css(styles.inline)}>
              <Share message={post.data.meta_description.text} url={url} prefix="Partager" />
            </div>
            <Title>{post.data.title_post.text}</Title>
            {post.first_publication_date && (
              <Span className={css(styles.meta)}>
                Le {moment(post.first_publication_date).format('D MMMM Y')},
                {author &&
                  author.data.name && (
                    <span className={css(styles.metaAuthor)}>par {author.data.name.text} </span>
                  )}
                - <span className={css(styles.categorie)}>{post.data.categories}</span>
              </Span>
            )}
            <div
              className={css(styles.content)}
              dangerouslySetInnerHTML={{ __html: this.formatBody(post.data.blog_post.html) }}
            />

            {post.data.body &&
              // eslint-disable-next-line consistent-return
              post.data.body.map(({ primary, __typename, ...rest }, i) => {
                switch (__typename) {
                  case 'PrismicPostBodyCta':
                    if (!primary || !primary.form_name) {
                      return null
                    }
                    return (
                      <Button
                        key={__typename + i}
                        to={`/${primary.form_name.text}`}
                        state={{ suggestions: post.relativeArticles }}
                        className={css(styles.cta)}
                      >
                        {t(primary.button_label.text)}
                      </Button>
                    )

                  case 'PrismicPostBodyText':
                    return (
                      <div
                        className={css(styles.content)}
                        dangerouslySetInnerHTML={{ __html: this.formatBody(primary.text.html) }}
                      />
                    )

                  case 'PrismicPostBodyLightBanner':
                    return (
                      <PostBodyBanner
                        key={__typename + i}
                        bannerTitle={primary.banner_title.text}
                        bannerImage={primary.banner_image.url}
                        bannerText={primary.text_banner.text}
                        buttonLabel={primary.button_label.text}
                        buttonLink={`/${primary.form_name.text}`}
                        state={{ suggestions: post.relativeArticles }}
                        appearance="light"
                      />
                    )
                  case 'PrismicPostBodyDarkBanner':
                    return (
                      <PostBodyBanner
                        key={__typename + i}
                        bannerTitle={primary.banner_title.text}
                        bannerImage={primary.banner_img.url}
                        bannerText={primary.banner_text.text}
                        buttonLabel={primary.button_label.text}
                        buttonLink={`/${primary.form_name.text}`}
                        state={{ suggestions: post.relativeArticles }}
                        appearance="dark"
                      />
                    )
                  case 'PrismicPostBodyClickableImage':
                    return (
                      <PostBodyClickableImage
                        key={__typename + i}
                        link={primary.link.url}
                        image={primary.image.url}
                        target={primary.link.target}
                      />
                    )
                  default:
                    break
                }
              })}

            {author && (
              <Author
                className={css(styles.author)}
                media={author.data.image.url}
                title={author.data.name.text}
                description={author.data.description.text}
              />
            )}
            <div className={css(styles.inline)}>
              <Share
                message={post.data.meta_description.text}
                url={location.href}
                prefix="Partager"
              />
            </div>
          </div>
          <Disqus config={disqusConfig} />
          <br />
          <br />
          <div className={css(styles.relativePostContainer)}>
            <Title className={css(styles.relativePostTitle)} level={2}>
              Derniers articles dans la même catégorie
            </Title>

            <div className={[css(blogStyles.articles)]}>
              {post.relativeArticles.map((post, i) => {
                return (
                  <Post
                    key={slugify(post.uid) + i}
                    className={css(blogStyles.article)}
                    to={post.uid}
                    media={post.data.illustration.url}
                    meta={`${moment(post.first_publication_date).format('D MMMM Y')}`}
                    categorie={post.data.categories}
                    title={post.data.title_post.text}
                    excerpt={this.reduceSummary(post.data.meta_description.text)}
                  />
                )
              })}
            </div>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

export default ArticleTemplate
